<template>
  <div class="block">
    <el-page-header @back="dynamic" content="任务维护" style="margin-bottom: 15px;">
    </el-page-header>
    <template>
      <el-button type="text">
        <el-button size="mini" type="primary" @click="outerVisible = true;">新增</el-button>
      </el-button>
      <el-dialog title="添加" :visible.sync="outerVisible">
        <el-form :model="dynamicForm">
          <el-form-item label="任务内容" :label-width="formLabelWidth"  class="w-50">
            <el-input v-model="dynamicForm.dyContent" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="类型" :label-width="formLabelWidth"  class="w-50">
            <el-radio v-model="dynamicForm.dyType" label="1" border size="mini">每日</el-radio>
            <el-radio v-model="dynamicForm.dyType" label="2" border size="mini">每月</el-radio>
          </el-form-item>
          <el-form-item label="重复" :label-width="formLabelWidth" class="w-50">
            <el-switch
                v-model="dynamicForm.dyRepeat"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="1"
                inactive-value="0">
            </el-switch>
          </el-form-item>
          <el-form-item label="完成期限" :label-width="formLabelWidth"  class="w-50">
            <el-date-picker
                v-model="dynamicForm.term"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="mini" @click="outerVisible = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="insertDy()">提交</el-button>
        </div>
      </el-dialog>
    </template>
    <el-table
        :data="dynamicData"
        style="width: 100%; margin: auto"
        :cell-style="{padding:0}"
        height="456">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="dyContent"
          label="任务内容">
      </el-table-column>
      <el-table-column
          prop="dyType"
          label="类型"
          width="50">
        <template slot-scope="scope">
          <el-tag v-if="(scope.row.dyType === 1)"  type="danger" size="mini">每日</el-tag>
          <el-tag v-if="(scope.row.dyType === 2)" size="mini">每月</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="dyRepeat"
          label="重复"
          width="50">
        <template slot-scope="scope">
          <el-tag v-if="(scope.row.dyRepeat === 0)"  type="danger" size="mini">独立</el-tag>
          <el-tag v-if="(scope.row.dyRepeat === 1)" size="mini">重复</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="finish"
          label="状态"
          width="70">
        <template slot-scope="scope">
          <el-tag v-if="(scope.row.finish === 0)"  type="danger" size="mini">未完成</el-tag>
          <el-tag v-if="(scope.row.finish === 1)" size="mini">完成</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="dyDate"
          label="任务日期"
          width="100">
      </el-table-column>
      <el-table-column
          prop="term"
          label="完成期限"
          width="100">
      </el-table-column>
      <el-table-column
          prop="finishDate"
          label="完成日期"
          width="100">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scopeDy">
          <el-button
              @click.native.prevent="open(scopeDy.row.dyId)"
              style="padding: 2px; margin: 5px">
            <i class="el-icon-delete"></i>
          </el-button>
          <el-button
              @click.native.prevent="queryDy(scopeDy.row.dyId)"
              style="padding: 2px; margin: 5px 0px">
            <i class="el-icon-edit"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="编辑" :visible.sync="editDy">
      <el-form :model="editDyForm">
        <el-form-item label="任务内容" :label-width="formLabelWidth"  class="w-50">
          <el-input v-model="editDyForm.dyContent" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="类型" :label-width="formLabelWidth"  class="w-50">
          <el-radio v-model="editDyForm.dyType" label="1" border size="mini">每日</el-radio>
          <el-radio v-model="editDyForm.dyType" label="2" border size="mini">每月</el-radio>
        </el-form-item>
        <el-form-item label="重复" :label-width="formLabelWidth" class="w-50">
          <el-switch
              v-model="editDyForm.dyRepeat"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="1"
              inactive-value="0">
          </el-switch>
        </el-form-item>
        <el-form-item label="完成期限" :label-width="formLabelWidth"  class="w-50">
          <el-date-picker
              v-model="editDyForm.term"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="editDy = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="editDynamic()">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "dynamicControl",
  data() {
    return {
      editDy: false,
      outerVisible: false,
      dynamicForm: {
        dyContent: '',
        dyType: '1',
        dyRepeat: 1,
        term: ''
      },
      editDyForm: {
        dyId: '',
        dyContent: '',
        dyType: '1',
        dyRepeat: 1,
        term: ''
      },
      formLabelWidth: '120px',
      dynamicData: []
    }
  },
  mounted() {
    this.getDynamicData()
  },
  methods: {
    dynamic() {
      this.$router.push("dynamic")
    },
    getDynamicData() {
      this.$http({
        method: 'get',
        url: '/api/dynamic/getDynamicList',
        data: []
      }).then((res)=>{
        this.dynamicData = res.data.data
      })
    },
    insertDy() {
      this.$confirm('新增该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          method: 'post',
          url: '/api/dynamic/insertDy/',
          data: this.dynamicForm
        }).then((res) =>{
          this.$message({
            type: 'success',
            message: res.data.msg
          });
          this.outerVisible = false
          this.getDynamicData()
          this.dynamicForm.dyContent = ''
          this.dynamicForm.term = ''
          this.dynamicForm.dyType = '1'
        }).catch(()=>{
          this.$message({
            type: 'error',
            message: "系统异常"
          })
        })
      }).catch(() => {
        // this.outerVisible = false
      });
      // this.dynamicForm.dyContent = ''
      // this.dynamicForm.term = ''
    },
    queryDy(dyId) {
      this.$http({
        method: 'get',
        url: '/api/dynamic/getDyById/' + dyId
      }).then((res) =>{
        this.editDyForm = res.data.data
        this.editDy = true
      })
    },
    editDynamic() {
      this.$confirm('是否修改该信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          method: 'put',
          url: '/api/dynamic/putDynamic/',
          data: this.editDyForm
        }).then((res) =>{
          this.$message({
            type: 'success',
            message: res.data.msg
          });
          this.editDy = false
          this.getDynamicData()
          this.dynamicForm.dyContent = ''
          this.dynamicForm.term = ''
          this.dynamicForm.dyType = '1'
        }).catch(()=>{
          this.$message({
            type: 'error',
            message: "系统异常"
          })
        })
      }).catch(() => {
        this.editDy = false
      });
    },
    delDyById(dyId) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          method: 'delete',
          url: '/api/dynamic/delDyById/' + dyId
        }).then((res) =>{
          this.$message({
            type: "success",
            message: res.data.msg
          })
          this.getDynamicData()
        })
      }).catch(() =>{
        this.$message({
          type: 'error',
          message: '取消删除！'
        })
      })
    }
  }
};
</script>

<style scoped>
.w-50 {
  width: 80%;
  margin: auto;
  margin-bottom: 5px;
}
</style>